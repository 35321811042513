@import './constants.scss';

.content{
    // height: calc(80vh - 133px);
    .first{
        display: flex;
        flex-direction: column;
        height: 75vh;
        justify-content: center;
        z-index: 2;
        cursor: pointer;
        .title{
            h1{
                font-weight: 900;
                font-size: 8em;
                display: block;
                text-align: center;
                max-width: 1000px;
                color: #000;
                margin: .6em auto;
            }
        }
        .arrow{
            display: flex;
            justify-content: center;
            span{
                font-size: 3em;
                color: #000;
                display: flex;
                cursor: pointer;
                transition: all .4s ease;
                border-radius: 50%;
            }
        }
    }
    @media(max-width: $screen-md){
        .first{
            .title{
                h1{
                    max-width: $screen-sm;
                    text-align: center;
                    margin: 45px 0;
                    font-size: 11vw;
                }
            }
            .arrow{
                span{
                    font-size: 3em;
                }
            }
        }
    }
    .transitionColors{
        margin-top: -25vh;
        height: 120vh;
        width: 100%;
        @media(max-width: $screen-md){ width: 100%; }
        .row{
            height: 100%;
            width: 100%;
            margin: 0;
            .bg1{
                background-color: $social;
                height: 100%;
                width: 100%;
            }
            .bg2{
                background-color: $ambiental;
                height: 100%;
                width: 100%;
            }
            .bg3{
                background-color: $economica;
                height: 100%;
                width: 100%;
            }
            .bg4{
                background-color: $cultural;
                height: 100%;
                width: 100%;
            }
        }
    }
}

.link{
    text-decoration: none;
}

.abstract{
    .content{
        font-weight: 700;
        font-size: 2.3vw;
        @media(max-width: $screen-md){ font-size: 30px; }
        @media(max-width: $screen-sm){ font-size: 20px; }
    }
}