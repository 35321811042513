@import '../../assets/styles/constants.scss';

body{
    &.social{
        .gallery .galleryItems .galleryItem::before{ background-color: transparentize($social, .7) }
    }
    &.ambiental{
        .gallery .galleryItems .galleryItem::before{ background-color: transparentize($ambiental, .7) }
    }
    &.economica{
        .gallery .galleryItems .galleryItem::before{ background-color: transparentize($economica, .7) }
    }
    &.cultural{
        .gallery .galleryItems .galleryItem::before{ background-color: transparentize($cultural, .7) }
    }
}

.bibl-items{
    .bibl-item{
        display: block;
        margin: 10px 0;
        .title{
            font-style: italic;
            &.title-link{
                cursor: pointer;
                a{
                    text-decoration: none;
                    color: #222;
                }
            }
        }
    }
}

.gallery{
    max-height: 100%;
    padding: 15px;
    font-size: 18px;
    .galleryItems{
        .galleryItem{
            cursor: pointer;
            margin: 15px 0;
            @media(min-width: $screen-md){ height: 200px; }
            padding: 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            position: relative;
            >svg{
                position: absolute;
                z-index: 10;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .4s ease;
                font-size: 3em;
                color: #fff;
                path{ stroke: #fff; }
                opacity: .4;
            }
            img{
                transition: all 0.3s ease;
                width: 100%;
                min-height: 200px;
                display: block;
                margin: 0 auto;
            }
            &:hover{
                img{ transform: scale(1.05); }
                .name{ 
                    top: 50%;
                    opacity: 1;
                }
                &::before{ opacity: 1; }
                &::after{
                    transform: scale(1);
                    opacity: 1;
                }
                >svg{
                    opacity: .9;
                    top: 20%;
                    font-size: 2em;
                    color: #fff;
                }
            }
            .name{
                position: absolute;
                top: 105%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                width: 90%;
                display: block;
                text-transform: uppercase;
                opacity: 0;
                margin: 0 auto;
                text-align: center;
                z-index: 11;
                transition: all .4s ease;
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #00000088;
                z-index: 10;
                opacity: 0;
                transition: all .4s ease;
            }
            &::after{
                content: '';
                position: absolute;
                top: 5%;
                left: 5%;
                height: 90%;
                width: 90%;
                transform: scale(1.2);
                opacity: 0;
                z-index: 10;
                transition: all .6s ease;
            }
        }
    }
}

.galleryModal{
    transition: all .4s ease;
    @media(max-width: $screen-md){
        max-height: 90vh;
        width: 90%;
    }
    &.imageOpen{
        max-width: 100% !important;
        .itemModal{
            .row{
                height: 90vh;
                .image{
                    img{
                        max-height: 100%;
                    }
                    svg{
                        top: 30px;
                        right: 30px;
                        color: red;
                        opacity: 1;
                        left: inherit;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
        }
    }
    .itemModal{
        .info{
            span, button, a{ font-size: .9em; }
        }
        .breadcrumbs{
            .breadcrumbItem{
                margin-right: 5px;
                font-size: .75em;
                font-weight: bold;
                text-transform: uppercase;
                &:not(:last-child){
                    &::after{
                        content: ' |';
                   }
                }
            }
        }
        .row{
            max-height: 80vh !important;
            .image, .image_obj{
                position: relative;
                cursor: pointer;
                display: flex;
                height: 100%;
                justify-content: center;
                @media(max-width: $screen-md){ margin: 10px 0; }
                img{
                    max-height: 400px;
                    display: block;
                    margin: 0 auto;
                    transition: all .4s ease;
                }
                svg, button{
                    @media(max-width: $screen-md){ display: none; }
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all .4s ease;
                    color: #fff;
                    opacity: .6;
                    font-size: 3em;
                    &:hover{
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                .image-gallery{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .image-gallery-content.fullscreen{
                        img{ max-height: 100%; }
                    }
                }
            }
            .image_obj{
                button{
                    width: 50px;
                    height: 50px;
                    border-width: 0;
                    top: 30px;
                    left: inherit;
                    right: 0;
                }
                svg{
                    max-width: 70px;
                    color: #000;
                }
            }
            .info{
                >span{
                    display: block;
                    margin: 15px 0;
                    @media(max-width: $screen-md){ margin: 5px 0; }
                    transition: all .4s ease;
                }
                .itemButton{
                    text-transform: capitalize;
                    border: 1px solid #000;
                    padding: 5px 10px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px 0;
                    cursor: pointer;
                }
                .itemMaterial{
                    font-style: italic;
                }
                .itemDesc{
                    text-align: justify;
                    @media(max-width: $screen-md){ overflow: auto; }
                    transition: all .3s ease;
                    max-height: 170px;
                    overflow: auto !important;
                    padding-right: 15px;
                    &.open{
                        max-height: 450px !important;
                        overflow: auto !important;
                    }
                }
                .btn-read{
                    color: #378aff;
                    text-align: right;
                    cursor: pointer;
                }
            }
        }
        .socialShares{
            margin-top: 15px;
            button{ margin: 0 5px }
        }
    }
}
