.abstractText{
    text-align: justify;
    &.level_3{ font-size: .6em; }
    &.min{ font-size: 1.5rem; }
    >strong span{ display: inline-flex; }
    .disabled{
        color: #000000aa;
        text-decoration: none;
        cursor: not-allowed;
    }
    .themeReference{
        cursor: pointer;
        text-decoration: none;
        display: inline-flex;
        color: #000;
        &:hover{ color: #000; }
        &.disabled{
            cursor: not-allowed;
        }
    }
}
