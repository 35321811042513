@import '../../assets/styles/constants.scss';

video::-moz-full-screen {
    display: none;
}

.modalPortal{
    &.open{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        .modalOverlay{
            .modalBody{
                position: absolute;
                width: 80%;
                max-width: 1170px;
                @media(max-width: $screen-md){ width: 80%; }
                @media(max-width: $screen-sm){ width: 90%; }
                @media(max-width: $screen-md) and (orientation: landscape){ max-height: 90vh; }
                top: 50%;
                left: 50%;
                border: 1px solid rgb(204, 204, 204);
                background: rgb(255, 255, 255);
                overflow: auto;
                outline: none;
                padding: 20px;
                padding-top: 35px;
                transform: translate(-50%, -50%);
                .video{
                    video{
                        @media(max-width: $screen-md) and (orientation: landscape){ max-height: 50vh; }
                        width: 80%;
                        display: block;
                        margin: 0 auto;
                        @media(max-width: $screen-md){ border-radius: 10px; }
                    }
                }
                .row{
                    margin: 0 -10px;
                    max-height: 170px;
                    display: flex;
                    overflow: auto;
                    .itemColumn{
                        img{
                            width: 100%;
                        }
                    }
                }
                .description{
                    display: block;
                    margin: 30px 0;
                }
                .itemColumn{
                    margin: 15px 0;
                    .thumbnail{
                        position: relative;
                        cursor: pointer;
                        @media(max-width: $screen-md){ border-radius: 5px; }
                        overflow: hidden;
                        svg{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 2em;
                            opacity: .5;
                            transition: all .4s ease;
                        }
                        &:hover{
                            svg{
                                opacity: .8;
                                transform: translate(-50%, -50%) scale(1.05);
                            }
                        }
                    }
                }
            }
        }
        &.g{
            .modalOverlay{
                .galleryModal{
                    max-width: 90%;
                    .galleryCarousel{
                        justify-content: space-between;
                        &.openDesc{
                            .carousel{
                                height: 0px !important;
                                width: 0% !important;
                                overflow: hidden;
                            }
                            .info{
                                // height: 400px;
                                overflow: auto;
                                max-height: 100% !important;
                            }
                        }
                        h3{
                            text-align: center;
                            font-size: 1em;
                            font-weight: bold;
                            margin-bottom: 15px;
                        }
                        .carousel{
                            margin: 0 auto;
                            transition: all .4s ease;
                            @media(max-width: $screen-md){
                                height: auto !important;
                                width: 70% !important;
                            }
                            >div{
                                overflow: inherit;
                                >div{
                                    &:nth-child(2){
                                        span{ font-size: .65em; }
                                    }
                                    &:nth-child(4){
                                        left: -35px;
                                    }
                                    &:nth-child(5){
                                        right: -35px;
                                    }
                                }
                            }
                        }
                        .info{
                            margin: 15px auto;
                            max-height: 150px;
                            overflow: hidden;
                            transition: all .4s ease;
                            .desc{ text-align: justify; }
                        }
                        .btn-info{
                            background-color: #fff;
                            border: 1px solid #000;
                        }
                    }
                }
            }
        }
    }
}