header{
    border-bottom: 2px solid #000;
    width: 100%;
    padding: 5px 15px;
    position: fixed;
    z-index: 1;
    background: #fff;
    .row{
        padding: 5px 15px;
        display: flex;
        justify-content: space-between;
        .languages{
            .headerLink{ margin-right: 15px; }
        }
        .link{
            text-align: right;
        }
    }
    .headerLink{
        text-transform: uppercase;
        cursor: pointer;
        font-weight: bold;
        color: #000;
        text-decoration: none;
    }
}