@import '../../assets/styles/constants.scss';

footer{
    background-color: #222;
    z-index: 101;
    @media(min-width: $screen-sm){
        height: auto;
        // position: absolute;
        width: 100%;
        bottom: -0;
    }
    .container{
        height: 100%;
        @media(max-width: $screen-sm){ padding: 0 45px; }
        .row{
            padding-bottom: 30px;
            display: flex;
            height: 100%;
            padding-top: 45px;
            >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media(max-width: $screen-sm){
                    margin-bottom: 15px;
                }
            }
            .images{
                img{
                    max-width: 60%;
                    margin-bottom: 15px;
                    display: block;
                }
            }
            .menu{
                ul{
                    list-style: none;
                    li{
                        margin: 15px 0;
                        a{
                            text-decoration: none;
                            color: #eaeaea;
                        }
                    }
                }
            }
            .lastCol{
                h4{
                    color: #eaeaea;
                    font-size: 1.35em;
                    text-align: center;
                    margin-bottom: 15px;
                }
                a.button{
                    background-color: transparent;
                    color: $ambiental;
                    border: 2px solid $ambiental;
                    text-transform: uppercase;
                    display: block;
                    margin: 15px auto 30px;
                }
                .social{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    a{
                        color: #eaeaea;
                        font-size: 1.2em;
                        transition: all .4s ease;
                        &:hover{
                            &.twitter{ color: #1D9BF0; }
                            &.facebook{ color: #4867AA; }
                            &.instagram{ color: #DE427E; }
                            &.youtube{ color: #FE0000; }
                            &.flickr{ color: #2567F1; }
                        }
                    }
                }
            }

        }
    }
}