$social: #792225;
$ambiental: #ee7a00;
$economica: #007aa3;
$cultural: #0d7966;

$tr_red: transparentize($social, .3);
$tr_green: transparentize($ambiental, .3);
$tr_blue: transparentize($economica, .3);
$tr_yellow: transparentize($cultural, .3);

$screen-sm: 768px;
$screen-md: 992px;