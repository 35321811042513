@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import './constants.scss';

*{ font-family: 'Spectral', serif; }

.btn{ border-radius: 0; }

/* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #444;
  }

body{
    // overflow: hidden;
    >iframe{ z-index: -20 !important; }
    &.terms{
        #root{
            .content{
                &.content-app{
                    padding-top: 30px;
                    >div{
                        height: 96.5vh !important;
                    }
                }
                >div{
                    overflow: auto !important;
                }
            }
        }
    }
}

#root{
    >*{
        // overflow: hidden;
    }
    .content{
        >div{
            @media(max-width: $screen-md){ overflow: auto !important; }
        }
    }
}

.hidden{ display: none !important; }

.btn-success{
    display: inline-flex;
    align-items: center;
    svg{
        margin-right: 10px;
    }
}

.ReactModalPortal{
    .ReactModal__Overlay{
        .ReactModal__Content{
            max-width: 1300px;
            margin: 0 auto;
        }
    }
}

.closeModal{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2em;
}

.itemModal{
    .itemBreadcrumbs{
        width: 100%;
        display: flex;
        justify-content: space-between;
        span{
            width: 100%;
            background-color: coral;
            color: #fff;
            font-weight: bold;
            text-align: center;
            padding: 15px;
        }
    }
    img{
        max-width: 100%;
    }
}