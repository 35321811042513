@import '../../assets/styles/constants.scss';

.menu{
    .title{
        padding: 10px 0;
        font-weight: bold;
        font-size: 1.1em;
        cursor: pointer;
    }
    .content{
        margin: 15px 0;
        margin-right: 15px;
        @media(max-width: $screen-md){
            overflow: auto;
            max-height: 70vh;
        }
        >ul.items{
            padding: 0;
            text-transform: uppercase; 
            >ul{
                padding: 0;
                >span{
                    padding: 0px 2rem;
                    padding-right: 0;
                    font-weight: bold;
                }
            }
        }
        ul.items{
            *{ cursor: pointer; }
            transition: all .4s ease;
            &.collapse{ padding-left: 0; }
            margin: 5px 0;
            >span{
                @media(max-width: $screen-md){ font-size: .9em; }
                padding: 2px 0;
                display: flex;
                align-items: center;
                width: 100%;
                display: flex;
                justify-content: space-between;
                span{
                    width: 80%;
                    padding: 1px;
                    transition: color .4s ease;
                }
                svg{
                    width: 20%;
                    height: auto;
                    padding: 5px;
                    max-height: 25px;
                }
            }
            li.item{
                padding: 0px 2rem;
                list-style-type: none;
                width: 100%;
            }
            &.terme-nivell-1{
                >ul{
                    
                }
            }
            &.terme-nivell-2{
                >ul{
                    text-transform: none;
                    margin: 0;
                    font-size: .9em;
                }
            }
        }
    }
}

body{
    .menu{
        &.last_level{
            font-size: 1rem;
            padding: 15px 0;
            @media(min-width: $screen-md){ display: none; }
            #last-level-item{
                .item{
                    display: block;
                    font-size: .8em;
                    padding: 5px;
                    padding-left: 2rem;
                    a{
                        color: #000;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
            .content{
                .title{
                    display: flex;
                    font-size: .7em;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                    padding-left: 2rem;
                    text-transform: uppercase;
                    svg{
                        width: 30%;
                        font-size: .8em;
                    }
                }
            }
        }
    }
    &.social{
        .menu.last_level .content .title{ background-color: $social; }
    }
    &.ambiental{
        .menu.last_level .content .title{ background-color: $ambiental; }
    }
    &.economica{
        .menu.last_level .content .title{ background-color: $economica; }
    }
    &.cultural{
        .menu.last_level .content .title{ background-color: $cultural; }
    }
}