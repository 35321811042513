@import './constants.scss';

.term-container{
    position: relative;
    height: 100%;
    .terms{
        display: flex;
        flex-wrap: wrap;
        height: 80%;
        margin: 0 30px;
        margin-top: 25vh;
        padding: 30px 0;
        @media(max-width: $screen-md){
            position: relative;
            flex-direction: column;
            height: 100%;
            &.open{
                aside{ width: 100%; }
            }
        }
        justify-content: space-between;
        .sidemenu{
            @media(min-width: $screen-md){
                display: none;
            }
        }
        >svg{
            position: absolute;
            top: 20px;
            height: 30px;
            width: 30px;
            padding: 5px;
            left: -5px;
            z-index: 1001;
        }
        &.open{
            >svg{
                left: 65vw;
            }
            aside{
                left: -30px;
                width: 95%;
                box-shadow: 0px 25px 20px #7f7f7f;
            }
        }
        aside{
            width: 30%;
            // height: 90vh;
            height: 100%;
            transition: all .4s ease;
            @media(max-width: $screen-md){
                padding: 15px;
                position: absolute;
                left: -100%;
                background-color: #fff;
                width: 0%;
                max-width: 330px;
                background: #fff;
                z-index: 101;
                height: 100%;
                top: 0;
                .menu{
                    height: 78vh;
                }
            }
            overflow: auto;
            .menu{
                .content{
                    @media(max-width: $screen-sm){ height: 100%; }
                }
            }
        }
        main{
            width: 60%;
            overflow: auto;
            height: 100%;
            padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
            @media(max-width: $screen-md){
                width: 100%;
                margin-top: 5vh;
            }
            margin-right: 5%;
            .contentTitle{
                text-align: center;
                font-weight: bold;
                display: none;
                @media(max-width: $screen-md){ font-size: 1.25rem; display: block; }
            }
            .contentVideo{
                @media(max-width: $screen-md) and (orientation: landscape){
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 15px;
                    video{ width: 40% !important; }
                    .informant{ width: 50%; }
                }
                video{
                    width: 70%;
                    @media(max-width: $screen-md){ border-radius: 10px; }
                }
                .learnMore{
                    margin: 15px 0;
                    display: flex;
                    justify-content: flex-end;
                    .btn{
                        border: 1px solid #000;
                        font-weight: bold;
                        &:hover{
                            background-color: #f1f1f1;
                        }
                    }
                }
                .informant{
                    font-size: 1.8rem;
                    display: block;
                    @media(max-width: $screen-md){ font-size: 90%; }
                    .name{ font-weight: bold; }
                    .surname{ font-weight: 300; }
                    .description{
                        display: block;
                        font-weight: 300;
                        font-style: italic;
                        font-size: .55em;
                        @media(max-width: $screen-md){ font-size: 70%; }
                    }
                }
            }
        }
    }
}