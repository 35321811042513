@import './constants.scss';


.beforeThemeReference{
    content: '';
    position: absolute;
    z-index: -1;
    width: 99%;
    height: 60%;
    left: .5%;
    top: 25%;
}

body{
    &.social{
        .level_3.abstractText{
            a{ color: $social; text-decoration: none; }
        }
        .terms{
            .contentTitle{ color: $social; }
        }
        .btn-success{
            background-color: $social;
            border-color: darken($social, 5%);
            &:hover{ background-color: darken($social, 10%); }
        }
        .modalPortal{
            &.open{background-color: transparentize($social, .7);  }
            .modalOverlay .modalBody .itemColumn{
                &.active{
                    .thumbnail{
                        border: 3px solid $social;
                    }
                }
            }
        }
    }
    &.ambiental{
        .level_3.abstractText{
            a{ color: $ambiental; text-decoration: none; }
        }
        .terms{
            .contentTitle{ color: $ambiental; }
        }
        .btn-success{
            background-color: $ambiental;
            color: #000;
            border-color: darken($ambiental, 5%);
            &:hover{ background-color: darken($ambiental, 10%); }
        }
        .modalPortal{
            &.open{ background-color: transparentize($ambiental, .7); }
            .modalOverlay .modalBody .itemColumn{
                &.active{
                    .thumbnail{
                        border: 3px solid $ambiental;
                    }
                }
            }
        }
    }
    &.economica{
        .level_3.abstractText{
            a{ color: $economica; text-decoration: none; }
        }
        .terms{
            .contentTitle{ color: $economica; }
        }
        .btn-success{
            background-color: $economica;
            border-color: darken($economica, 5%);
            &:hover{ background-color: darken($economica, 10%); }
        }
        .modalPortal{
            &.open{ background-color: transparentize($economica, .7); }
            .modalOverlay .modalBody .itemColumn{
                &.active{
                    .thumbnail{
                        border: 3px solid $economica;
                    }
                }
            }
        }
    }
    &.cultural{
        .level_3.abstractText{
            a{ color: $cultural; text-decoration: none; }
        }
        .terms{
            .contentTitle{ color: $cultural; }
        }
        .btn-success{
            background-color: $cultural;
            color: #000;
            border-color: darken($cultural, 5%);
            &:hover{ background-color: darken($cultural, 10%); }
        }
        .modalPortal{
            &.open{ background-color: transparentize($cultural, .7); }
            .modalOverlay .modalBody .itemColumn{
                &.active{
                    .thumbnail{
                        border: 3px solid $cultural;
                    }
                }
            }
        }
    }


    //  MENU
.social{
    span.active{ color: $social; }
    >span{ background-color: $social; color: #000 !important; }
    &.themeReference{
        position: relative;
        &::before{
            @extend .beforeThemeReference;
            background: transparentize($social, .7);
        }
        &:hover{
            &::before{
                background: $social;
            }
        }
    }
}
.ambiental{
    span.active{ color: $ambiental; }
    >span{ background-color: $ambiental; color: #000 !important; }
    &.themeReference{
        position: relative;
        &::before{
            @extend .beforeThemeReference;
            background: transparentize($ambiental, .7);
        }
        &:hover{
            &::before{
                background: $ambiental;
            }
        }
    }
}
.economica{
    span.active{ color: $economica; }
    >span{ background-color: $economica; color: #000 !important; }
    &.themeReference{
        position: relative;
        &::before{
            @extend .beforeThemeReference;
            background: transparentize($economica, .7);
        }
        &:hover{
            &::before{
                background: $economica;
            }
        }
    }
}
.cultural{
    span.active{ color: $cultural; }
    >span{ background-color: $cultural; color: #000 !important; }
    &.themeReference{
        position: relative;
        &::before{
            @extend .beforeThemeReference;
            background: transparentize($cultural, .7);
        }
        &:hover{
            &::before{
                background: $cultural;
            }
        }
    }
}
}